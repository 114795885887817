const style = getComputedStyle(document.body);
const colors = {
  primary: style.getPropertyValue('--color-primary'),
  danger: '220 53 69',
  warning: '255 193 7',
  secondary: style.getPropertyValue('--color-secondary'),
  info: '23 162 184',
  success: '40 167 69',
};

$(function () {
  // On Theme Change
  document.addEventListener("ThemeChanged", () => {
    colors.primary = style.getPropertyValue('--color-primary');
    colors.secondary = style.getPropertyValue('--color-secondary');
  });

});

function getColors() {
  return colors;
}

function calculateColorSpilts(num) {
  const spilts = ['.2', '.35', '.65'];
  const colorValues = Object.values(colors);
  const colorsPerSplit = Math.ceil(num / colorValues.length);
  const values = [];

  for (let i = 0; i < colorValues.length; i++) {
    spilts.slice(0, colorsPerSplit)
      .map(function (s) { return "rgb(" + colorValues[i] + "/ " + s + ")" })
      .forEach(function (cv) { values.push(cv) });
  }
  // console.log(values);
  return values;
}

function polarAreaChart(ctx, labels, data) {
  return new Chart(ctx, {
    type: "polarAreaWithShadow",
    data: {
      labels: labels,
      datasets: [
        {
          data: data,
          backgroundColor: calculateColorSpilts(labels.length),
          borderColor: 'rgb(82, 82, 91)',
          borderWidth: 1,
        },
      ],
    },
    options: {
      maintainAspectRatio: false,
      plugins: {
        legend: {
          position: "bottom",
          labels: {
            usePointStyle: true,
            padding: 20,
          },
        },
        // tooltip: tooltipOptions,
      },
      scales: {
        r: {
          ticks: {
            display: false,
          },
        },
      },
      layout: {
        padding: 5,
      },
    },
  });
}

function lineAreaChart(ctx, labels, data) {
  const chartData = {
    labels: labels,  // Date labels
    datasets: [
      {
        label: 'Paid Transactions',
        data: data[0],  // Paid transaction count
        borderColor: "rgba(" + colors.success + "/ 1)",
        backgroundColor: "rgba(" + colors.success + "/ 0.2)",
        fill: true,  // Area under the line
        tension: 0.4  // Smoothens the line
      },
      {
        label: 'Created Transactions',
        data: data[1],  // Created transaction count
        borderColor: "rgba(" + colors.info + "/ 1)",
        backgroundColor: "rgba(" + colors.info + "/ 0.2)",
        fill: true,
        tension: 0.4
      }
    ]
  };
  return new Chart(ctx, {
    type: 'line',
    data: chartData,
    options: {
      responsive: true,
      scales: {
        x: {
          title: {
            display: true,
            text: 'Date'
          }
        },
        y: {
          title: {
            display: true,
            text: 'Transaction Count'
          }
        }
      },
      plugins: {
        legend: {
          position: "bottom",
          labels: {
            usePointStyle: true,
            padding: 20,
          },
        },
        // tooltip: tooltipOptions,
      },
    }
  });
}

function updateLineAreaChart(chartInstance, scaleTitle, data) {
  chartInstance.options.scales.y.title.text = scaleTitle;
  chartInstance.data.datasets[0].data = data[0];
  chartInstance.data.datasets[1].data = data[1];
  chartInstance.update();
}

function createChart(elementId, labels, data, type = 'polar-area') {
  if (typeof Chart === "undefined") return;

  const ctx = document.getElementById(elementId);
  if (!ctx) return;
  ctx.getContext("2d");

  switch (type) {
    case 'polar-area':
      polarAreaChart(ctx, labels, data);
      break;
    case 'line-area':
      lineAreaChart(ctx, labels, data);
      break;

    default:
      break;
  }
}

function updateChart(elementId, newTitle, data, type = 'polar-area') {
  if (typeof Chart === "undefined") return;

  const ctx = document.getElementById(elementId);
  if (!ctx) return;
  const chartInstance = Chart.getChart(ctx);
  if (!chartInstance) return;

  switch (type) {
    case 'polar-area':
      break;
    case 'line-area':
      updateLineAreaChart(chartInstance, newTitle, data);
      break;

    default:
      break;
  }
}


window.dashboard_scope = {
  createChart: createChart,
  updateChart: updateChart
}
